import { i18n } from 'next-i18next';
import { Language, languageSelection } from '@/Translations';
import isClient from '@/Util/globals';

export const loadTranslations = (): any => {
  let translations: any = {};

  if (!i18n || !i18n.isInitialized) {
    console.info('Klaro: i18n is not initialized. Using default english translations.');
    return undefined;
  }

  for (const lng of languageSelection) {
    const translationsObject = i18n.getResourceBundle(lng, 'klaro');
    if (translationsObject) {
      translations[lng] = translationsObject;
    } else {
      console.error(`Klaro: no translations found for language: ${lng}`);
    }
  }
  return translations;
};

export const getLanguageKlaro = () => {
  if (!isClient()) return Language.NL.toLocaleLowerCase();
  const formattedPathname = `${window.location.pathname}/`;
  const splittedPath = formattedPathname.split('/');
  const foundLanguage = Object.values(languageSelection).find(lng => splittedPath.includes(lng));
  return foundLanguage || Language.NL;
};

export const addModalHeader = () => {
  const translations = loadTranslations();
  const langUsed = getLanguageKlaro();
  const translation = translations[langUsed];
  const modal = document.querySelector('.cn-body');
  const header = document.createElement('div');
  header.innerHTML = translation.header;
  header.className = 'cn-header';
  modal?.insertBefore(header, modal.firstChild);
};

export const moveAnalyticsButton = () => {
  const learnMoreButton = document.querySelector('.cm-btn-lern-more') as HTMLButtonElement | null;
  const noticeParagraph = document.querySelector('.cn-body p') as HTMLParagraphElement | null;
  const cookieNoticeSpan = document.querySelector('.cookie-notice span') as HTMLElement | null;
  if (learnMoreButton && noticeParagraph && cookieNoticeSpan) {
    noticeParagraph.appendChild(learnMoreButton);
    learnMoreButton.className = 'contentNotice';
    const cleanText = cookieNoticeSpan.textContent?.trim() || '';
    learnMoreButton.textContent = cleanText;
    const paragraphWrapper = document.createElement('p');
    paragraphWrapper.textContent = cleanText;
    learnMoreButton.innerHTML = '';
    learnMoreButton.appendChild(paragraphWrapper);
    cookieNoticeSpan.remove();
  }
};

export const setKlaroElements = () => {
  function addHideButtonListener(): void {
    const hideButton: HTMLButtonElement | null = document.querySelector('.klaro .hide');
    if (hideButton) {
      hideButton.addEventListener('click', () => {
        moveAnalyticsButton();
        addModalHeader();
      });
    }
  }

  const observer: MutationObserver = new MutationObserver(() => {
    addHideButtonListener();
  });
  const klaroElement: HTMLElement | null = document.querySelector('.klaro');
  if (klaroElement) {
    observer.observe(klaroElement, {
      childList: true,
      subtree: true
    });
  }
  addHideButtonListener();
};
